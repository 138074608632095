<template>
  <div class="">
    <trac-loading v-if="isLoading"></trac-loading>
    <div
      @click="dismissModal"
      v-if="selectedBusinessModalCtrl"
      class="modal fixed bg-opacity-20 bg-gray-700 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
    >
      <div
        class="bg-white flex justify-between items-start w-5/12 p-8 rounded"
        v-if="selectedBusiness"
      >
        <div class="">
          <h3 class="text-lg text-gray-800 mb-5">
            {{ selectedBusiness.business_name }}
          </h3>
          <p class="text-sm mt-1 text-gray-800">
            {{ selectedBusiness.settlement_bank }}
          </p>
          <p class="text-sm mt-1 text-gray-800">
            {{ selectedBusiness.account_number }}
          </p>
          <p class="text-sm mt-1 text-gray-800">
            Created At:
            <span>{{
              moment(selectedBusiness.created_at).format("Do MMM, YYYY")
            }}</span>
          </p>
        </div>
        <img :src="selectedBusiness.bank_logo" alt="" />
      </div>
      <div
        class="h-24 bg-white flex justify-between items-start w-5/12 p-8 rounded"
        v-else
      >
        <p>
          No bank information registered to {{ clickedMerchant.name }}.
        </p>
      </div>
    </div>
    <h2 class="my-2 font-semibold text-blue-">Dataset</h2>
    <form
      @submit.prevent="searchForMerchant"
      class="relative text-gray-600 mt-10 w-7/12"
    >
      <input
        type="search"
        name="serch"
        placeholder="Search Merchant Name..."
        v-model="searchText"
        class="bg-white h-10 px-5 pr-10 w-full rounded-full text-sm"
      />
      <button type="submit" class="absolute right-0 top-0 mt-3 mr-4">
        <svg
          class="h-4 w-4 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 56.966 56.966"
          style="enable-background:new 0 0 56.966 56.966;"
          xml:space="preserve"
          width="512px"
          height="512px"
        >
          <path
            d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
          />
        </svg>
      </button>
    </form>
    <div class="w-10/12 mx-auto mt-12" v-if="merchants">
      <div class="" v-if="merchants.length > 0">
        <div
          @click="selectBusiness(merchant)"
          class="shadow-lg m-4 flex items-center hover:bg-gray-300 cursor-pointer"
          v-for="(merchant, i) in merchants"
          :key="i"
        >
          <div class="flex justify-center items-center bg-gray-900 p-4 w-28">
            <h1 class="text-6xl uppercase">
              {{ merchant.name.substring(0, 1) }}
            </h1>
          </div>
          <div class="ml-4">
            <h2 class="text-gray-700 capitalize text-lg">
              {{ merchant.name }}
            </h2>
            <p class="text-xs mt-1 text-gray-500">
              {{ merchant.email }}
            </p>
          </div>
        </div>
      </div>
      <div class="h-18 flex justify-center items-center mt-56" v-else>
        <p class="text-gray-500 text-base">No match found...</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      moment,
      isLoading: false,
      selectedBusinessModalCtrl: false,
      searchText: "",
      merchants: null,
      selectedBusiness: null,
      clickedMerchant: null,
    };
  },
  methods: {
    dismissModal(e) {
      const target = e.target;
      if (target.classList.contains('modal')) {
        this.selectedBusinessModalCtrl = false
      }
    },
    async selectBusiness(merchant) {
      this.clickedMerchant = merchant;
      this.isLoading = true;
      await this.$store.dispatch(
        "FETCH_MERCHANT_DETAILS",
        merchant.business_id
      );
      const res = this.$store.getters["GET_SELECTED_MERCHANT_RES"];

      this.$store.commit("SETUP_SELECTED_MERCHANT_RES", null);

      if (res.status) {
        this.selectedBusiness = res.data ? res.data[0] : null;
        this.selectedBusinessModalCtrl = true;
      } else {
        alert(
          res.error ||
            res.message ||
            "Error: Please check network or contact admin."
        );
      }
      this.isLoading = false;
    },
    async searchForMerchant() {
      if (this.searchText.length < 3) {
        alert("Searched text must be at least three or more characters.");
        return;
      }
      this.isLoading = true;
      await this.$store.dispatch("FETCH_SEARCH_MERCHANTS", this.searchText);
      const res = this.$store.getters["GET_SEARCHED_MERCHANT_RES"];

      this.$store.commit("SETUP_SEARCHED_MERCHANTS_RES", null);

      if (res.status) {
        const data = res.data ? res.data.items || [] : [];
        this.merchants = data.sort((a, b) =>
          a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ? 1 : -1
        );
      } else {
        alert(
          res.error ||
            res.message ||
            "Error: Please check network or contact admin."
        );
      }

      this.isLoading = false;
    },
  },
};
</script>

<style></style>
